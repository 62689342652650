import React, { useEffect, useRef } from "react";
import "./MainButton.css";
import gsap from "gsap";

function MainButton({ label, onClick }) {
  const buttonRef = useRef(null);

  useEffect(() => {
    const button = buttonRef.current;

    const animate = (e) => {
      const glow = document.createElement("div");
      glow.classList.add("glow");
      gsap.to(e.currentTarget, {
        keyframes: [
          {
            duration: 2,
            ease: "none",
            onStart: () => {
              button.appendChild(glow);
              gsap.to(glow, {
                inset: "-2em",
                opacity: 0,
                duration: 1.3,
              });
            },
            onComplete: () => {
              button.removeChild(glow);
            },
          },
        ],
      });
    };

    button.addEventListener("click", animate);

    return () => {
      button.removeEventListener("click", animate);
    };
  }, []);

  return (
    <button ref={buttonRef} className="custom-button" onClick={onClick}>
      {label}
    </button>
  );
}

export default MainButton;
