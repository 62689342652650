import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "./Particle";

const Impressum = () => {
  return (
    <Container fluid className="impressum-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              <strong className="purple">Impressum</strong>
            </h1>
            <p>
              <strong>Angaben gemäß § 5 TMG</strong>
            </p>
            <p>
              Henning Schwichow
              <br />
              Fanny-Lewald-Ring 65
              <br />
              38446 Wolfsburg
              <br />
            </p>
            <p>
              <strong>Kontakt:</strong>
              <br />
              Telefon: +49 (0) 176 3139 0278
              <br />
              E-Mail: henning.schwichow@gmx.de
              <br />
            </p>
            <h1>Datenschutzerklärung</h1>
            <p>
              <strong>Verantwortlicher für die Datenverarbeitung:</strong>
              <br />
              Henning Schwichow
              <br />
              Fanny-Lewald-Ring 651
              <br />
              38446 Wolfsburg
              <br />
              E-Mail: henning.schwichow@gmx.de
              <br />
            </p>
            <p>
              <strong>
                Erhebung und Speicherung personenbezogener Daten sowie Art und
                Zweck von deren Verwendung:
              </strong>
              <br />
              Beim Besuch der Website werden folgende Daten erhoben:
              <br />
              - IP-Adresse
              <br />
              - Datum und Uhrzeit der Anfrage
              <br />
              - Zeitzonendifferenz zur Greenwich Mean Time (GMT)
              <br />
              - Inhalt der Anforderung (konkrete Seite)
              <br />
              - Zugriffsstatus/HTTP-Statuscode
              <br />
              - jeweils übertragene Datenmenge
              <br />
              - Website, von der die Anforderung kommt
              <br />
              - Browser
              <br />
              - Betriebssystem und dessen Oberfläche
              <br />
              - Sprache und Version der Browsersoftware
              <br />
            </p>
            <p>
              <strong>Rechtsgrundlage der Datenverarbeitung:</strong>
              <br />
              Art. 6 Abs. 1 S. 1 lit. f DSGVO
            </p>
            <p>
              <strong>Weitergabe von Daten:</strong>
              <br />
              Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen
              als den im Folgenden aufgeführten Zwecken findet nicht statt.
            </p>
            <p>
              <strong>Rechte der Nutzer:</strong>
              <br />
              - Auskunftsrecht
              <br />
              - Recht auf Berichtigung
              <br />
              - Recht auf Löschung
              <br />
              - Recht auf Einschränkung der Verarbeitung
              <br />
              - Recht auf Datenübertragbarkeit
              <br />
              - Widerspruchsrecht
              <br />
              - Recht auf Widerruf der datenschutzrechtlichen
              Einwilligungserklärung
              <br />
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Impressum;
