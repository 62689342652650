import React from "react";
import { useTranslation } from "react-i18next";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const languages = [
  {
    code: "en",

    country_code: "us",
  },
  {
    code: "de",
    country_code: "de",
  },
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = () => {
    const newLanguage = i18n.language === "en" ? "de" : "en";
    i18n.changeLanguage(newLanguage);
  };

  const nextLanguage = languages.find((lang) => lang.code !== i18n.language);

  return (
    <div>
      <button
        onClick={changeLanguage}
        style={{
          backgroundColor: "transparent",
          color: "white",
          padding: "10px 20px",
          border: "none",
          cursor: "pointer",
        }}
      >
        {nextLanguage && (
          <>
            <span
              className={`fi fi-${nextLanguage.country_code}`}
              style={{ marginRight: "8px" }}
            />
            {nextLanguage.name}
          </>
        )}
      </button>
    </div>
  );
};

export default LanguageSelector;
