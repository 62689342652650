import React from "react";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import MainButton from "../Button/MainButton.js";

function SiteCards(props) {
  const { t } = useTranslation();
  return (
    <Card className="Site-card-view">
      <Card.Img
        className="site-card-img"
        variant="top"
        src={props.imgPath}
        alt="card-img"
      />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>

        {"\n"}
        {"\n"}

        {!props.isBlog && props.demoLink && (
          <a href={props.demoLink} target="_blank" rel="noreferrer">
            <MainButton label={t("website")} />
          </a>
        )}
      </Card.Body>
    </Card>
  );
}
export default SiteCards;
