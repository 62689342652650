import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../components/LanguageSelector.js";

const LandingPage = ({ onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleYesClick = () => {
    onClose();
    navigate("/home");
  };

  const handleNoClick = () => {
    window.location.href = "https://www.google.com";
  };

  return (
    <div className="landing-page-container">
      <h1 className="landing-page-title">Lia Love</h1>
      <h1 className="landing-page-age">{t("age")}</h1>
      <div className="button-container">
        <button className="landing-page-button" onClick={handleYesClick}>
          {t("yes")}
        </button>
        <button className="landing-page-button" onClick={handleNoClick}>
          {t("no")}
        </button>
      </div>
      <LanguageSelector />
    </div>
  );
};

export default LandingPage;
