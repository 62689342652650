import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./index.css";
import App from "./App";
import LandingPage from "./components/LandingPage";
import reportWebVitals from "./reportWebVitals";

function Root() {
  const [showLandingPage, setShowLandingPage] = useState(true);

  const handleLandingPageClose = () => {
    setShowLandingPage(false);
  };

  return (
    <Router>
      <Routes>
        {showLandingPage ? (
          <Route
            path="/"
            element={<LandingPage onClose={handleLandingPageClose} />}
          />
        ) : (
          <>
            <Route path="/*" element={<App />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
